import React from 'react';
import ReactDOM from 'react-dom/client';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Workspace } from 'polotno/canvas/workspace';
import { SidePanel } from 'polotno/side-panel';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { createStore } from 'polotno/model/store';
import { getImageSize } from 'polotno/utils/image';
import { observer } from 'mobx-react-lite';
// import { InputGroup } from '@blueprintjs/core';
import { TemplatesSection } from './templates-panel';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';


// import all default sections
// we will not use all of them in the demo
// just to show what we have
import {
  TextSection,
  ElementsSection,
  UploadSection,
  BackgroundSection,
  SizeSection,
} from 'polotno/side-panel';
//
import { SectionTab } from 'polotno/side-panel';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
// import our own icon
import MdPhotoLibrary from '@meronex/icons/md/MdPhotoLibrary';

const store = createStore({
  // this is a demo key just for that project
  // (!) please don't use it in your projects
  // to create your own API key please go here: https://polotno.com/cabinet
  key: 'nFA5H9elEytDyPyvKL7T',
  // you can hide back-link on a paid license
  // but it will be good if you can keep it for Polotno project support
  showCredit: true,
});
const searchParams = new URLSearchParams(document.location.search);
const agent = searchParams.get('agent') !== undefined && searchParams.get('agent') && searchParams.get('agent').length > 0 ? searchParams.get('agent'): null;
const agentImage = searchParams.get('id') !== undefined && searchParams.get('id') && searchParams.get('id').length > 0 ? './agents/'+searchParams.get('id')+'.webp': '';
const agentEmail = searchParams.get('email') !==undefined && searchParams.get('email') && searchParams.get('email').length > 0 ? searchParams.get('email'): null;
const agentPhone = searchParams.get('phone') !==undefined && searchParams.get('phone')  &&  searchParams.get('phone').length > 0 ? searchParams.get('phone'): null;
const initcap = (str: string) => str[0].toUpperCase() + str.substring(1).toLowerCase();
store.addPage();
if(agent){
const agentName = agent.split('-');
let agentFullName = '';
agentName.forEach((item)=>{
  agentFullName += initcap(item) +' ';
})
console.log(agentFullName);
store.activePage.addElement({
  type: 'text',
  id: '1',
  x: 270,
  y:980,
  text:agentFullName,
  placeholder: '',
  fontSize: 22,
  fontFamily: 'Roboto',
  fontStyle: 'normal', 
  fontWeight: 'normal', 
  textDecoration: '',
  width: 250,
  lineHeight: 1.5,
  fill: '#000',
  align: 'center',
  stroke: '#000',
  backgroundEnabled: true,
  backgroundColor: '#000',
});
}
if(agentImage){
store.activePage.addElement({
  type: 'image',
  id:'4',
  x: 80,
  y:880,
  rotation: 0,
  src: agentImage,
  keepRatio: false, // can we change aspect ratio of image
  clipSrc: '',
  width: 200,
  height: 200,
  selectable: true,
  alwaysOnTop: false,
  showInExport: true,
  draggable: true,
  contentEditable: true,
  removable: true,
  resizable: true,
});
}
if(agentEmail){
store.activePage.addElement({
  type: 'text',
  id: '2',
  x: 270,
  y:1010,
  text:initcap(agentEmail),
  placeholder: '',
  fontSize: 22,
  fontFamily: 'Roboto',
  fontStyle: 'normal', 
  fontWeight: 'normal', 
  textDecoration: '',
  width: 370,
  lineHeight: 1.5,
  fill: '#000',
  align: 'center',
  stroke: '#000',
  backgroundEnabled: true,
  backgroundColor: '#000',
});
}
if(agentPhone){
store.activePage.addElement({
  type: 'text',
  id: '3',
  x: 270,
  y:1040,
  text:agentPhone,
  placeholder: '',
  fontSize: 22,
  fontFamily: 'Roboto',
  fontStyle: 'normal', 
  fontWeight: 'normal', 
  textDecoration: '',
  width: 235,
  lineHeight: 1.5,
  fill: 'black',
  align: 'center',
  stroke: 'black',
  backgroundEnabled: true,
  backgroundColor: 'black',
});
}

store.activePage.addElement({
  type: 'image',
  id:'5',
  x: 965,
  y:965,
  rotation: 0,
  src: './fam-icon.png',
  keepRatio: false, // can we change aspect ratio of image
  clipSrc: '',
  width: 100,
  height: 100,
  selectable: true,
  alwaysOnTop: false,
  showInExport: true,
  draggable: true,
  contentEditable: true,
  removable: true,
  resizable: true,
});
store.activePage.addElement({
  type: 'image',
  id:'6',
  x: 0,
  y:0,
  rotation: 0,
  src: './safa-one-239169-120116.jpg',
  keepRatio: false, // can we change aspect ratio of image
  clipSrc: '',
  width: 1080,
  height: 860,
  selectable: true,
  alwaysOnTop: false,
  showInExport: true,
  draggable: true,
  contentEditable: true,
  removable: true,
  resizable: true,
});
export const PhotosPanel = observer(({ store }) => {
  const [images, setImages] = React.useState([]);
  async function loadImages() {
    // here we should implement your own API requests
    setImages([]);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    // for demo images are hard coded
    // in real app here will be something like JSON structure
    setImages([
      { url: agentImage},
      { url: './fam.svg' },
      { url: './fam-logo.png' },
      { url: './fam-logo-HD-2-white.png' },
      { url: './fam-logo-HD-2-gray.png' },
      { url: './1.png' },
      { url: './2.png' },
      { url: './fam-icon.png' },
      { url: './fam-luxe-black.png' },
      { url: './round-fam-red.png' },
      { url: './round-fam-black.png' },
      { url: './fam-icon-blue.png' },
      { url: './DXB-logo.png' },
      { url: './DXB-logo.svg' },
      { url: './WhatsApp-blue.jpg' },
      { url: './WhatsApp-blue.jpg' },
      { url: './5100.png' },
      { url: './promo/best-price.png' },
      { url: './promo/hot-deal.png' },
      { url: './promo/hot-deal1.png' },
      { url: './promo/investors-deal-1.png' },
      { url: './promo/investors-deal-2.png' },
      { url: './promo/limited-time-offer1.png' },
      { url: './promo/limited-time-offer2.png' },
      { url: './promo/limited-time-offer3.png' },
      { url: './promo/lower-price.png' },
      { url: './promo/priced-to-sell-1.png' },
      { url: './promo/priced-to-sell-2.png' },
      { url: './promo/ready-to-move.png' },
      { url: './bluewaters-bay-307637-120630.jpg' },
      { url: './burj-binghatti-213320-094939.jpg' },
      { url: './canal-front-residences-101761-131115.jpg' },
      { url: './cavalli-estates-726179-163448.jpg' },
      { url: './chic-tower-by-de-grisogono-710693-124633.jpg' },
      { url: './damac-lagoons-282947-101439.jpg' },
      { url: './dorchester-collection-dubai-317253-111336.jpg' },
      { url: './WhatsApp-blue.jpg' },
      { url: './WhatsApp-blue.jpg' },
      { url: './google-reviews_v2.png' },
      { url: './5100.png' },
      { url: './bluewaters-bay-307637-120630.jpg' },
      { url: './burj-binghatti-213320-094939.jpg' },
      { url: './canal-front-residences-101761-131115.jpg' },
      { url: './cavalli-estates-726179-163448.jpg' },
      { url: './chic-tower-by-de-grisogono-710693-124633.jpg' },
      { url: './damac-lagoons-282947-101439.jpg' },
      { url: './dorchester-collection-dubai-317253-111336.jpg' },
      { url: './elie-saab-a-vie-at-the-fields-581498-194609.jpg' },
      { url: './elitz-by-danube-221156-151229.jpg' },
      { url: './emaar-beachfront-344331-110509.jpg' },
      { url: './gemz-by-danube-581716-113004.jpg' },
      { url: './injazaat-residence-344307-152919.jpg' },
      { url: './jumeirah-living-business-bay-160826-121954.jpg' },
      { url: './mudon-al-ranim-121547-114204.jpg' },
      { url: './one-zaabeel-104416-124251.jpg' },
      { url: './port-de-la-mer-344521-130225.jpg' },
      { url: './safa-one-239169-120116.jpg' },
      { url: './six-senses-331261-115044.jpg' },
      { url: './sobha-marina-205521-135344.jpg' },
      { url: './st-regis-the-residences-240244-114023.jpg' },
      { url: './the-palm-gateway-273948-154359.jpg' },
      { url: './the-s-tower-269612-105905.jpg' },
      { url: './tilal-al-ghaf-344333-113415.jpg' },
      { url: './w-residences-downtown-dubai-527448-133851.jpg' },
      { url: './akoya-oxygen-dubai-201210.jpeg' },
      { url: './al-barsha-dubai-205676.jpg' },
      { url: './bluewaters-island-dubai-205370.jpg' },
      { url: './business-bay-dubai-1.jpg' },
      { url: './dubai-creek-harbour-emaar-205365.jpg' },
      { url: './dubai-hills-estate-205364.jpg' },
      { url: './dubai-marina-205361.jpg' },
      { url: './palm-jumeirah-dubai-205369.jpg' },
      { url: './jumeirah-bay-island-dubai-205793.jpg' },
      { url: './jumeirah-golf-estates-dubai-205784.jpg' },
    ]);
  }

  React.useEffect(() => {
    loadImages();
  }, []);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          loadImages();
        }}
        style={{
          marginBottom: '20px',
        }}
      /> */}
      {/* you can create yur own custom component here */}
      {/* but we will use built-in grid component */}
      <ImagesGrid
        images={images}
        getPreview={(image) => image.url}
        onSelect={async (image, pos) => {
          const { width, height } = await getImageSize(image.url);
          store.activePage.addElement({
            type: 'image',
            src: image.url,
            width,
            height,
            // if position is available, show image on dropped place
            // or just show it in the center
            x: pos ? pos.x : store.width / 2 - width / 2,
            y: pos ? pos.y : store.height / 2 - height / 2,
          });
        }}
        rowsNumber={2}
        isLoading={!images.length}
        loadMore={false}
      />
    </div>
  );
});

// Footer styles
const mystyle = {
  backgroundColor: "white",
  position: "absolute",
  right: "4px",
  bottom: "4px",
  textAlign: "center",
  padding: "3px 6px",
  display:"flex",
  alignItems:"center",
  borderRadius:"4px"
};

// Define the new custom section
const CustomPhotos = {
  name: 'photos',
  Tab: (props) => (
    <SectionTab name="Photos" {...props}>
      <MdPhotoLibrary />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: PhotosPanel,
};

// we will have just two sections
const sections = [
  CustomPhotos,
  TemplatesSection,
  TextSection,
  ElementsSection,
  UploadSection,
  BackgroundSection,
  // we will replace default resize with our own
  SizeSection,
];

export const App = () => {
  return (
    <PolotnoContainer className="polotno-app-container">
      <SidePanelWrap>
        <SidePanel store={store} sections={sections} defaultSection="photos" />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar store={store} downloadButtonEnabled />
        <Workspace store={store} />
        <ZoomButtons store={store} />
        <div style={mystyle}>Powered by <h4 style={{margin:"5px"}}><span style={{color:'#e72636e3'}}>f&auml;m</span> Properties</h4></div>
      </WorkspaceWrap>
    </PolotnoContainer>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
